.myChart {
    min-height: 230px !important;
}

.d-activity {
    min-height: 230px !important;
}

.d-main {
    min-height: 245px !important;
}